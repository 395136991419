<template>
  <v-container fluid class="icons-page">
    <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
      <v-col cols="12" md="6">مجالات الاسعار</v-col>

      <v-col>
        <v-row>
          <!-- حقل البحث -->
          <v-col cols="6" md="8">
            <v-text-field
              v-model="searchQuery"
              label="بحث بالاسم"
              append-icon="mdi-magnify"
              single-line
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <!-- زر إضافة نوع جديد -->
          <v-col cols="6" md="4" class="d-flex align-center justify-center">
            <v-btn color="primary" @click="openAddForm"> إضافة مجال سعر </v-btn>
          </v-col>
        </v-row>

        <!-- قائمة العناصر مع خاصية السحب والإفلات وعمود التفعيل -->
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:body>
            <tr
              v-for="(item, index) in filteredItems"
              :key="item.id"
              draggable="true"
              @dragstart="onDragStart(index)"
              @dragover.prevent
              @drop="onDrop(index)"
              class="draggable-row"
            >
              <!-- عمود السحب والإفلات -->
              <td>
                <v-icon small class="drag-handle" style="cursor: move"
                  >mdi-drag</v-icon
                >
                {{ index + 1 }}
              </td>
              <!-- الأعمدة العادية -->
              <td>{{ item.min }}</td>
              <td>{{ item.max }}</td>
              <!-- عمود التفعيل -->
              <td>
                <v-switch
                  v-model="item.is_active"
                  @change="(value) => toggleActivation(item, value)"
                  :label="item.is_active ? 'مفعل' : 'معطل'"
                ></v-switch>
              </td>
              <td>
                <v-icon small @click.stop="openEditForm(item)"
                  >mdi-pencil</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
        <!-- نموذج التعديل والإضافة -->
        <v-dialog class="form" v-model="dialog_form" max-width="500px">
          <v-form ref="form" class="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="edit.max"
              label="الحد الأعلى"
              type="number"
              required
              :rules="[
                (v) => v !== null || 'الحد الأعلى مطلوب',
                (v) =>
                  Number(v) > Number(edit.min) ||
                  'الحد الأعلى يجب أن يكون أكبر من الحد الادنى',
              ]"
              @input="validateMinValue"
            />

            <v-text-field
              v-model="edit.min"
              label="الحد الأدنى"
              type="number"
              required
              :rules="[
                (v) => v !== null || 'الحد الأدنى مطلوب',
                (v) =>
                  Number(v) < Number(edit.max) ||
                  'الحد الأدنى يجب أن يكون أقل من الحد الأعلى',
              ]"
            />

            <v-btn class="btn" @click="saveItem(edit)" :disabled="!valid">
              {{ isNew ? "إضافة" : "تعديل" }}
            </v-btn>
            <v-btn text @click="dialog_form = false"> إلغاء </v-btn>
          </v-form>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      isNew: true, // القيمة الافتراضية هي 'إضافة'
      dialog_form: false,
      edit: { min: null, max: null },
      valid: false, // للتحقق من صحة النموذج
      items: [],
      searchQuery: "",
      draggedItemIndex: null,
      filteredItems: [],
      api: {
        getAll: "priceDomains",
        create: "newPriceDomain",
        edit: "editPriceDomain",
        reorder: "editPriceDomainOrder",
      },
      headers: [
        { text: "#", align: "start", sortable: false, value: "id" },
        { text: "الحد الأدنى", align: "start", sortable: false, value: "min" },
        { text: "الحد الأعلى", align: "start", sortable: false, value: "max" },
        {
          text: "الحالة",
          align: "center",
          sortable: false,
          value: "is_active",
        },
        { text: "العمليات", value: "actions", sortable: false },
      ],
    };
  },

  methods: {
    openAddForm() {
      this.isNew = true; // تحديد أنه سيتم إضافة عنصر جديد
      this.edit = { min: null, max: null }; // قيم فارغة للإضافة الجديدة
      this.dialog_form = true; // فتح النموذج
    },

    openEditForm(item) {
      this.isNew = false; // تحديد أنه سيتم تعديل عنصر موجود
      this.edit = { ...item };
      this.dialog_form = true; // فتح النموذج
    },

    async fetchItems() {
      try {
        const response = await axios.get(this.api.getAll);
        // تحويل is_active من نص إلى قيمة منطقية
        this.items = response.data.data.map((item) => ({
          ...item,
          is_active: item.is_active === "1", // تحويل '1' إلى true و '0' إلى false
        }));
        this.filteredItems = this.items;
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    },
    async saveItem(formData) {
      if (this.$refs.form.validate()) {
        try {
          const formattedData = {
            ...formData,
            min: Number(formData.min),
            max: Number(formData.max),
          };

          let request;
          if (this.isNew) {
            request = await axios.post(this.api.create, formattedData);
          } else {
            const updatedFormData = {
              ...formattedData,
              price_domain_id: formData.id,
            };
            request = await axios.put(this.api.edit, updatedFormData);
          }

          if (request.status === 200) {
            this.$toast.success(
              this.isNew ? "تم إضافة البيانات بنجاح" : "تم تعديل البيانات بنجاح"
            );
            this.fetchItems();
            this.dialog_form = false;
          }
        } catch (error) {
          console.error("Error saving item:", error);
          this.$toast.error("حدث خطأ أثناء العملية");
        }
      } else {
        console.log("الرجاء ملء النموذج بشكل صحيح");
      }
    },
    async toggleActivation(item, value) {
      try {
        // تحويل القيمة إلى '1' إذا كانت true وإلى '0' إذا كانت false
        const updatedStatus = value ? "1" : "0";
        await axios.put(`${this.api.edit}`, {
          is_active: updatedStatus,
          price_domain_id: item.id,
        });

        // تحديث حالة العنصر كقيمة منطقية بعد الحفظ
        item.is_active = value; // تعيين القيمة كـ true أو false
        this.$toast.success(`تم ${value ? "تفعيل" : "تعطيل"} نوع العقار بنجاح`);
        this.fetchItems();
      } catch (error) {
        console.error("Error toggling activation:", error);
        this.$toast.error("حدث خطأ أثناء تغيير الحالة");
      }
    },
    filterItems() {
      const query = this.searchQuery.trim();
      this.filteredItems = query
        ? this.items.filter(
            (item) =>
              item.min.toString().includes(query) ||
              item.max.toString().includes(query)
          )
        : this.items;
    },
    onDragStart(index) {
      this.draggedItemIndex = index;
    },
    onDrop(index) {
      if (this.draggedItemIndex !== null && this.draggedItemIndex !== index) {
        const itemsCopy = [...this.filteredItems]; // نسخ القائمة
        const draggedItem = itemsCopy[this.draggedItemIndex];
        itemsCopy.splice(this.draggedItemIndex, 1); // إزالة العنصر من مكانه القديم
        itemsCopy.splice(index, 0, draggedItem); // إدراج العنصر في المكان الجديد

        // تحديث الترتيب
        this.filteredItems = itemsCopy.map((item, idx) => ({
          ...item,
          order: idx + 1, // إعادة تعيين ترتيب العناصر
        }));

        // إرسال الطلب إلى الخادم لحفظ الترتيب
        const priceDomains = this.filteredItems.map((item) => ({
          id: item.id,
          order: item.order,
        }));
        axios
          .put(this.api.reorder, { priceDomains })
          .then(() => {
            this.$toast.success("تم تحديث ترتيب العناصر بنجاح");
          })
          .catch((error) => {
            console.error("Error saving order:", error);
            this.$toast.error("فشل في تحديث ترتيب العناصر");
          });

        this.draggedItemIndex = null; // إعادة تعيين المؤشر
      }
    },
  },

  mounted() {
    this.fetchItems();
  },
};
</script>

<style scoped>
.form {
  background-color: rgb(247, 247, 246);
  padding: 10px;
}
.btn {
  background-color: rgb(75, 75, 247);
  z-index: 10;
}
</style>
