<template>
  <v-btn @click="exportToExcel" :disabled="!hasData">
    <template v-if="!hasData">
      <v-progress-circular
        indeterminate
        color="white"
        size="20"
      ></v-progress-circular>
    </template>
    <template v-else> تصدير إلى Excel </template>
  </v-btn>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    hasData() {
      // التحقق من وجود بيانات قابلة للتصدير
      return Array.isArray(this.tableData) && this.tableData.length > 0;
    },
  },
  methods: {
    exportToExcel() {
      if (!this.hasData) {
        console.error("No data available to export");
        this.$toast.error("لايوجد بيانات للتصدير");
        return;
      }

      // تحويل البيانات إلى ملف Excel
      const ws = XLSX.utils.json_to_sheet(this.tableData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Data");
      XLSX.writeFile(wb, "data.xlsx");
    },
  },
};
</script>
