import axios from "axios";

const state = () => ({
  tableData: [],
  loading: true,
  item: {},
});
const users = [
  {
    id: 1,
    first_name: "أيمن",
    last_name: "الدعبل",
    phone: 343243253,
    authentication: "taj@gmail.com",
    address: "Damascus, Baramika",
  },
  {
    id: 2,
    first_name: "أيمن",
    last_name: "الدعبل",
    phone: 343243253,
    authentication: "taj@gmail.com",
    address: "Damascus, Baramika",
  },
  {
    id: 3,
    first_name: "أيمن",
    last_name: "الدعبل",
    phone: 343243253,
    authentication: "taj@gmail.com",
    address: "Damascus, Baramika",
  },
];
const offices = [
  {
    name: "مكتب التاج العقاري",
    phone: "135435135",
    all_connections: 50,
    create_date: "1/3/2021",
    last_date: "1/3/2022",
    address: "damascus",
  },
  {
    name: "مكتب السلطان العقاري",
    phone: "135435135",
    all_connections: 50,
    create_date: "1/3/2021",
    last_date: "1/3/2022",
    address: "damascus",
  },
];
const estates = [
  {
    office_name: "مكتب التاج العقاري",
    estate_type: "بيت",
    views: 50,
    likes: 50,
    address: "damascus",
    price: 20000,
    state: "قيد الانتظار",
  },
  {
    office_name: "مكتب السلطان العقاري",
    estate_type: "فيلا",
    views: 50,
    likes: 50,
    address: "damascus",
    price: 20000,
    state: "مرفوض",
  },
];
const orders = [
  {
    order_name: "aaa",
    type: "aaa",
    phone: 54656450,
    details: "ssss gggg",
    location: "damascus",
    date: "1/1/2022",
  },
  {
    order_name: "aaa",
    type: "aaa",
    phone: 54656450,
    details: "ssss gggg",
    location: "damascus",
    date: "1/1/2022",
  },
  {
    order_name: "aaa",
    type: "aaa",
    phone: 54656450,
    details: "ssss gggg",
    location: "damascus",
    date: "1/1/2022",
  },
];

const actions = {
  async fetchTableData({ commit }, api_info) {
    commit("SET_LOADING", true);
    commit("SET_TABLE_DATA", []);

    if (!api_info || !api_info.api || !api_info.api.getAll) {
      console.error("API or getAll endpoint is undefined.");
      commit("SET_LOADING", false);
      return;
    }

    let params = { params: this.state.filter.filter };

    try {
      const res = await axios.get(`${api_info.api.getAll}`, params);
      let resultData = res.data.data;
      commit("SET_TABLE_DATA", resultData);
    } catch (err) {
      console.error("Error fetching table data:", err.message || err);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async fetchTableDataFilter({ commit }, api_info) {
    commit("SET_LOADING", true);
    commit("SET_TABLE_DATA", []);
    var filter = this.state.filter.filter;
    // Object.keys(filter)
    //     .forEach(function eachKey(object_key) {
    //         if (filter[object_key] != null && filter[object_key].hasOwnProperty("key")) {
    //             if (filter[object_key].key == null) {
    //                 filter[object_key] = null
    //             }
    //         }
    //     });

    let params = {
      params: filter,
    };
    await axios
      .get(`${api_info.api.getAll}`, params)
      .then((res) => {
        let resultData = res.data.data;
        let result = {
          resultData: resultData,
          filter: api_info.filter,
        };
        commit("SET_TABLE_DATA_FILTER", result);
        commit("SET_LOADING", false);
      })
      .catch((err) => {
        switch (api_info.api.sgetAll) {
          case "users":
            commit("SET_TABLE_DATA", users);
            break;
          case "offices":
            commit("SET_TABLE_DATA", offices);
            break;
          case "estates":
            commit("SET_TABLE_DATA", estates);
            break;
          case "orders":
            commit("SET_TABLE_DATA", orders);
            break;
        }
        commit("SET_LOADING", false);
      });
  },

  async deleteItem({ dispatch, commit }, data) {
    commit("SET_LOADING", true);
    commit("SET_TABLE_DATA", []);
    await axios
      .delete(`${data.api.delete}=${data.id}`)
      .then(() => {
        this._vm.$toast.success("تمت عملية الحذف بنجاح");
      })
      .catch(() => {
        this._vm.$toast.error("فشلت عملية الحذف");
      })
      .finally(() => {
        dispatch("fetchTableData", data);
      });
  },
};

const getters = {
  getTableData: (state) => {
    return state.tableData;
  },
  getLoading: (state) => {
    return state.loading;
  },
};

const mutations = {
  SET_TABLE_DATA(state, payload) {
    state.tableData = payload;
  },
  SET_TABLE_DATA_FILTER(state, payload) {
    const filters = payload.filter;
    let ArrayResult = payload.resultData;

    // تصفية المفاتيح بناءً على الشروط العامة
    const filterKeys = Object.keys(filters).filter((key) => {
      const value = filters[key];
      if (key === "count" && value.key === null) return false;
      if (key === "date" && value.key === null) return false;
      if (key === "per_page") return false;
      if (["sale", "rent", "is_activated"].includes(key) && value === 0)
        return false;
      if (value === null) return false;
      return true;
    });

    // إذا لم يكن هناك مفاتيح تصفية صالحة، نعيد النتيجة الأصلية.
    if (filterKeys.length === 0) {
      state.tableData = ArrayResult;
      return;
    }

    // تطبيق التصفية
    ArrayResult = ArrayResult.filter((item) => {
      return filterKeys.every((key) => {
        const value = filters[key];
        switch (key) {
          case "interior_status":
          case "estate_type":
          case "contract":
            return item[key]?.id === value;
          case "sale":
            return item.estate_offer_type?.id === 1;
          case "rent":
            return item.estate_offer_type?.id === 2;
          case "calls":
          case "price":
            const itemValue = +item[key];
            const filterValue = +value.value;
            if (value.key === "bigger") return itemValue > filterValue;
            if (value.key === "smaller") return itemValue < filterValue;
            return itemValue === filterValue;
          case "date":
            const diffDays = Math.floor(
              Math.abs(new Date() - new Date(item.created_at)) /
                (1000 * 60 * 60 * 24)
            );
            const filterValueDate = value.value;
            if (value.attr === "day") {
              return value.key === "bigger"
                ? diffDays > filterValueDate
                : diffDays < filterValueDate;
            } else if (value.attr === "month") {
              const diffMonths = diffDays / 30;
              return value.key === "bigger"
                ? diffMonths > filterValueDate
                : diffMonths < filterValueDate;
            } else {
              const diffYears = diffDays / 365;
              return value.key === "bigger"
                ? diffYears > filterValueDate
                : diffYears < filterValueDate;
            }
          case "Location":
            return item.location?.locations[0]?.id === value;
          default:
            return item[key] === value;
        }
      });
    });

    // التأكد من أن النتيجة النهائية مصفوفة
    state.tableData = Array.isArray(ArrayResult) ? ArrayResult : [];
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
