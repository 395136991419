<template>
  <div class="search-container">
    <input
      v-model="query"
      :placeholder="placeholder"
      class="search-input"
      @keyup.enter="performSearch"
      @input="performSearch"
      @focus="showDropdown = true"
      @blur="hideDropdown"
    />
    <button class="search-button" @click="performSearch">بحث</button>

    <ul v-if="showDropdown && searchResults.length > 0" class="dropdown-list">
      <li
        v-for="(result, index) in searchResults"
        :key="index"
        @click="goToOffice(result.id)"
      >
        {{ result.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    placeholder: {
      type: String,
      default: "ابحث عن المكاتب...",
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      query: "", // النص المدخل في حقل البحث
      searchResults: [], // تخزين النتائج محليًا
      showDropdown: false, // التحكم في ظهور القائمة المنسدلة
    };
  },
  methods: {
    // دالة البحث
    async performSearch() {
      if (this.query.trim() === "") {
        this.searchResults = []; // إذا كان النص فارغًا، امسح النتائج
        return;
      }

      try {
        const url = `/admin/allOffices`;
        const params = {
          page: this.page,
          page_size: this.pageSize,
          search: this.query,
        };

        const response = await axios.get(url, { params });
        this.searchResults = response.data.data; // حفظ النتائج في الخاصية المحلية
      } catch (error) {
        console.error("حدث خطأ أثناء البحث:", error);
      }
    },

    // إخفاء القائمة عند الخروج من المدخل
    hideDropdown() {
      setTimeout(() => {
        this.showDropdown = false;
      }, 200); // تأخير بسيط لإعطاء فرصة لاختيار العنصر قبل الاختفاء
    },

    // الانتقال إلى صفحة المكتب عند النقر على نتيجة البحث
    goToOffice(id) {
      const url = `/estate-offices/${id}`;
      window.open(url, "_blank"); // فتح الرابط في تبويب جديد
    },
  },
};
</script>

<style scoped>
.search-container {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative; /* مهم لتحديد موقع القائمة المنسدلة */
}

.search-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
}

.search-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}

/* تصميم القائمة المنسدلة بحيث تظهر فوق حقل البحث */
.dropdown-list {
  position: absolute;
  top: 100%; /* تظهر تحت حقل البحث */
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 5px; /* المسافة بين المدخل والقائمة */
  list-style-type: none;
  padding: 0;
}

.dropdown-list li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}
</style>
